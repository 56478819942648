<template>
  <div class="info">
    <top-bar :title="'人大信箱'" :left="true"></top-bar>
    <div v-show="visible">
      <top-bar title="诉求信息"></top-bar>
      <!-- 所在小区 -->
      <van-popup v-model="subareaShow" position="bottom">
        <van-picker
          :disabled="id"
          title="请选择"
          show-toolbar
          :columns="selector.subareaOptions"
          value-key="name"
          @confirm="subareaConfirm"
          @cancel="subareaShow = false"
        />
      </van-popup>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">诉求信息</span>
          </template>
        </van-cell>
        <van-cell title="所在小区" :value="selector.subareaTitle" @click="subareaShow = edit" :clickable="edit"
                  :value-class="{'value-common':selector.subareaTitle=='请选择'}" is-link />
        <!-- 诉求详情 -->
        <van-cell title="诉求标题">
          <van-field
        :disabled="!!id"
        v-model="selector.letterTitle"
        placeholder="请输入"
        input-align="right"
      />
        </van-cell>
      <van-cell title="诉求详情">
        <van-field
          :disabled="!!id"
          v-model="selector.letterContent"
          rows="4"
          autosize
          type="textarea"
          placeholder="这是一条诉求详情"
        />
      </van-cell>
      </van-cell-group>
       <van-cell-group>
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">人大反馈结果</span>
          </template>
        </van-cell>
         <van-cell title="反馈结果">
        <van-field
          :disabled="checkUser !== null"
          v-model="dataForm.checkContent"
          rows="4"
          autosize
          type="textarea"
          placeholder="这是一条反馈结果"
        />
      </van-cell>
       </van-cell-group>
      <van-row class="btns">
        <van-col :span="24" v-if="checkUser === null">
          <van-button
            type="info"
            size="large"
            :loading="loading"
            round
            @click="submit"
            >完成</van-button
          >
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";

export default {
  components: {
    topBar,
  },
  data() {
    return {
      visible: true,
      edit: false,
      loading: false,
      page: 1,
      limit: 10,
      list: [],
      orgId: "",
      userId: "",
      id: "",
      isCheck: "",
      checkUser: null,
      subareaShow: false,
      selector: {
        //所在小区
        subareaTitle: "请选择",
        subareaOptions: [],
        subarea: "",
        letterTitle: "",
        letterContent: "",
      },
      dataForm: {
        checkContent: "",
      },
    };
  },
  methods: {
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/org/subAreaList"),
        method: "post",
        params: this.$http.adornParams({
          communityId: this.$orgId,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.selector.subareaOptions = data.subAreaList;
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selector.subareaTitle = value.name;
        this.selector.subarea = value.id;
      }
      this.subareaShow = false;
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl(
          "/wxapp/future/letter/info/" + this.id
        ),
        method: "post",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.selector.subareaTitle = data.futureLetter.subareaStr;
          this.selector.letterTitle = data.futureLetter.letterTitle || "";
          this.selector.letterContent = data.futureLetter.letterContent || "";
          this.dataForm.checkContent = data.futureLetter.checkContent || "";
          if (
            data.futureLetter.checkUser ||
            data.futureLetter.checkUser !== 0
          ) {
            // that.setData({
            this.checkUser = data.futureLetter.checkUser;
            // })
          }
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // 提交
    submit() {
      if (this.dataForm.checkContent === "") {
        return this.$toast.fail("失败:请先输入内容");
      }
      this.loading = true;
      this.$http({
        url: this.$http.adornUrl(`/wxapp/future/letter/letterCheck`),
        method: "post",
        data: {
          id: this.id,
          userId: this.userId,
          checkContent: this.dataForm.checkContent || "反馈成功",
        },
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `添加成功`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1);
              }, 1500);
            },
          });
        } else {
          this.loading = false;
          this.$toast.fail(data.msg);
        }
      });
      this.loading = false;
    },
    // 初始化
    init() {
      this.getSubareaList();
      this.getInfo()
    },
  },
  created() {
    this.id = this.$route.query.id || "";
    document.querySelector("html").style.backgroundColor = "#fff";
    this.orgId = this.$orgId;
    this.userId = this.$globalData.userInfo.userId;
    this.init();
  },
  beforeDestroy() {
    document.querySelector("html").style.backgroundColor = "";
  },
};
</script>
